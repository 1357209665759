export const ERROR_CART = {
    MAX_QUANTITY: "Vượt quá số lượng đặt hàng cho phép",
    NOT_ACTIVE_SKU: "Sản phẩm tạm ngưng bán",
    SUSPENDED_SKU: "Sản phẩm đã ngưng bán",
    OUT_OF_STOCK_SKU: "Sản phẩm tạm hết hàng",
    OUT_OF_STOCK: "Sản phẩm tạm hết hàng",
    CART_ITEM_INVALID: "Sản phẩm không tìm thấy thông tin",
    NOT_AVAILABLE_SKU: "Sản phẩm chưa được hỗ trợ ở khu vực này",
    NOT_FOUND_SKU: "Sản phẩm không tìm thấy thông tin",
    NOT_FOUND_PRICE_SKU: "Sản phẩm không tìm thấy thông tin",
    COMBO_INVALID: "Khuyến mãi đã hết hạn",
    TYPE_INVALID: "Giỏ hàng có sự thay đổi sản phẩm, vui lòng kiểm tra lại",
    QUANTITY_INVALID: "Giỏ hàng có sự thay đổi về số lượng sản phẩm, vui lòng kiểm tra lại",
    SKU_MISSING: "Giỏ hàng có sự thay đổi về sản phẩm, vui lòng kiểm tra lại",
    SKU_NOT_FOUND: "Giỏ hàng có sự thay đổi về sản phẩm, vui lòng kiểm tra lại",
    TOTAL_PRICE_NOT_MATCH: "Giỏ hàng có sự thay đổi về giá sản phẩm, vui lòng kiểm tra lại",
    PAYMENT_METHOD_NOT_MATCH:
        "Giỏ hàng có sự thay đổi về phương thức thanh toán, vui lòng kiểm tra lại",
    DELIVERY_METHOD_NOT_MATCH:
        "Giỏ hàng có sự thay đổi về phương thức vận chuyển, vui lòng kiểm tra lại",
    VOUCHER_NOT_MATCH: "Giỏ hàng có sự thay đổi về mã giảm giá, vui lòng kiểm tra lại",
    CUSTOMER_ORANGE_NOT_SUPPORT:
        "Tài khoản của bạn không hỗ trợ thanh toán bằng tiền mặt khi nhận hàng",
    VOUCHER_CODE_INVALID: "Giá trị đơn hàng chưa đủ để áp dụng mã khuyến mãi!",
    REQUIRED_CERTIFICATE:
        "Bạn chưa đủ giấy phép để mua SP của nhà cung cấp này. Vui lòng chọn SP tương tự của NCC khác",
    INVALID_NOT_YET_DEBT_DAY: "Thời gian hợp đồng không hợp lệ, vui lòng kiểm tra lại",
    INVALID_INSUFFICIENT_BALANCE:
        "Số hạn mức khả dụng không đủ, vui lòng kiểm tra lại hạn mức hoặc thanh toán để tăng lại hạn mức.",
    DEBT_CONTRACT_INACTIVE: "Hợp đồng công nợ đang bị tắt",
    INVALID_NEED_TO_PAY_ORDER_OVERDUE:
        "Tài khoản quý khách có đơn hàng quá hạn thanh toán, vui lòng thanh toán để có thể tiếp tục đặt hàng.",
    INVALID_NEED_TO_PAY_DEBT: "Quý khách vui lòng thanh toán dư nợ để có thể tiếp tục đặt hàng.",
};
export const ERROR_CODE_CART = {
    MAX_QUANTITY: "MAX_QUANTITY",
    NOT_ACTIVE_SKU: "NOT_ACTIVE_SKU",
    SUSPENDED_SKU: "SUSPENDED_SKU",
    OUT_OF_STOCK_SKU: "OUT_OF_STOCK_SKU",
    OUT_OF_STOCK: "OUT_OF_STOCK",
    CART_ITEM_INVALID: "CART_ITEM_INVALID",
    NOT_AVAILABLE_SKU: "NOT_AVAILABLE_SKU",
    NOT_FOUND_SKU: "NOT_FOUND_SKU",
    NOT_FOUND_PRICE_SKU: "NOT_FOUND_PRICE_SKU",
    COMBO_INVALID: "COMBO_INVALID",
    CHANGED_PRICE: "CHANGED_PRICE",
    TYPE_INVALID: "TYPE_INVALID",
    QUANTITY_INVALID: "QUANTITY_INVALID",
    SKU_MISSING: "SKU_MISSING",
    SKU_NOT_FOUND: "SKU_NOT_FOUND",
    TOTAL_PRICE_NOT_MATCH: "TOTAL_PRICE_NOT_MATCH",
    PAYMENT_METHOD_NOT_MATCH: "PAYMENT_METHOD_NOT_MATCH",
    DELIVERY_METHOD_NOT_MATCH: "DELIVERY_METHOD_NOT_MATCH",
    VOUCHER_NOT_MATCH: "VOUCHER_NOT_MATCH",
    CUSTOMER_ORANGE_NOT_SUPPORT: "CUSTOMER_ORANGE_NOT_SUPPORT",
    VOUCHER_CODE_INVALID: "VOUCHER_CODE_INVALID",
    REQUIRED_CERTIFICATE: "REQUIRED_CERTIFICATE",
    INVALID_NOT_YET_DEBT_DAY: "INVALID_NOT_YET_DEBT_DAY",
    INVALID_INSUFFICIENT_BALANCE: "INVALID_INSUFFICIENT_BALANCE",
    INVALID_PARSE_DATA: "INVALID_PARSE_DATA",
    REQUIRED_CUSTOMER_ID: "REQUIRED_CUSTOMER_ID",
    REQUIRED_ORDER_CODE: "REQUIRED_ORDER_CODE",
    REQUIRED_TYPE: "REQUIRED_TYPE",
    REQUIRED_TOTAL_ORDER_AMOUNT: "REQUIRED_TOTAL_ORDER_AMOUNT",
    DEBT_CONTRACT_INACTIVE: "DEBT_CONTRACT_INACTIVE",
    INVALID_NEED_TO_PAY_ORDER_OVERDUE: "INVALID_NEED_TO_PAY_ORDER_OVERDUE",
    INVALID_NEED_TO_PAY_DEBT: "INVALID_NEED_TO_PAY_DEBT",
};
export const ERROR_PRODUCTS = [
    ERROR_CODE_CART.MAX_QUANTITY,
    ERROR_CODE_CART.NOT_ACTIVE_SKU,
    ERROR_CODE_CART.SUSPENDED_SKU,
    ERROR_CODE_CART.OUT_OF_STOCK_SKU,
    ERROR_CODE_CART.OUT_OF_STOCK,
    ERROR_CODE_CART.CART_ITEM_INVALID,
    ERROR_CODE_CART.NOT_AVAILABLE_SKU,
    ERROR_CODE_CART.NOT_FOUND_SKU,
    ERROR_CODE_CART.NOT_FOUND_PRICE_SKU,
    ERROR_CODE_CART.REQUIRED_CERTIFICATE,
];

export default ERROR_CART;
