export const TopManufacturer = [
    "A6EQ1PGEQ1",
    "F2V84FW961",
    "HXDBCV84FW",
    "TA2AXHR2AX",
    "UD72AXHR2A",
    "KVPGEQ1PGE",
    "8LCV84FW96",
    "TTYJYJYJYJ",
    "2E84FW961P",
    "G8T61PGEQ1",
    "H8R2AXHR2A",
    "WHJYJYJYJY",
    "BVPGEQ1PGE",
    "DUU72AXHR2",
];

export default { TopManufacturer };
