export const FETCH_PRODUCT_SUCCESS = "FETCH_PRODUCT_SUCCESS";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";
export const ADD_ITEM = "ADD_ITEM";
export const REMOVE_ITEM = "REMOVE_ITEM";
export const INCREASE = "INCREASE";
export const INCREASE_BY = "INCREASE_BY";
export const DECREASE = "DECREASE";
export const CLEAR = "CLEAR";
export const CHECKOUT = "CHECKOUT";
export const ADD_IMPORTANT = "ADD_IMPORTANT";
export const REMOVE_IMPORTANT = "REMOVE_IMPORTANT";
export const SELECT_ITEM = "SELECT_ITEM";
export const SELECT_ALL_ITEM = "SELECT_ALL_ITEM";
export const UN_SELECT_ALL_ITEM = "UN_SELECT_ALL_ITEM";

export default { FETCH_PRODUCT_SUCCESS };
