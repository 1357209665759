export const MOBILE = /Android|BlackBerry|iPhone|iPod|Opera Mini|IEMobile|WPDesktop/i;
export const browserRegex =
    /(?:Chrome|CriOS|Edge|Firefox|FxiOS|IEMobile|Opera Mini|OPR|Safari|SamsungBrowser|Silk)\/([0-9.]+)/; // tach trinh duyet va phien ban
export const osRegex = /(Android|CPU(?: i[a-zA-Z]+)? OS|iPhone OS|Mac OS X|Windows NT) ([0-9._]+)/; // tach he dieu hanh va phien ban
export const deviceRegex = /\(([^)]+)\)/; // tach thiet bi
export default {
    MOBILE,
    browserRegex,
    osRegex,
    deviceRegex,
};
