import { VISITED_URLS_STORAGE_KEY } from "constants/data";

const clearStorage = () => {
    if (window && window.localStorage) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in localStorage) {
            if (!key?.startsWith("ins") && !key?.startsWith("sp")) {
                localStorage.removeItem(key);
            }
        }
        localStorage.setItem("logout", new Date());
        localStorage.setItem(VISITED_URLS_STORAGE_KEY, "[]");
    }
};

export default {
    clearStorage,
};
