// CÁC TAGS ƯU TIÊN
export const mapPrioritizedTags = [
    "NEAR_EXPIRATION",
    "HAODONNHANH",
    "HANG_DAT_TRUOC",
    "CO_O_KHO",
    "BAN_CHAY",
    "GIA_TOT",
    "NHAN_HANG_3TOT",
    "GIAONHANH",
    "FLASH_SALE",
];

export const ENUM_TAG_CODES = {
    HANG_DIEM: "Y7GB",
    HANG_HANG: "2ABC",
    HOADONNHANH: "HOADONNHANH",
    KHUYENMAI: "DEAL",
};

export default {
    mapPrioritizedTags,
    ENUM_TAG_CODES,
};
